body {
  margin: 0.5em;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.Student {
  border: 2px solid #d3d3d3;
  border-radius: 0.5em;
  margin-bottom: 5em;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  padding: 1em;
  text-align: left;
  min-height: "300px";
}

.form {
  padding: 1em;
}

.red {
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #d9534f;
}

.required {
  font-size: "14px";
  color: #d9534f;
  align-items: "start";
}

.consent {
  display: flex;
  font-size: 13;
  font-weight: "bold";
}

.checkbox {
  padding-right: 0.25rem;
}

.loaderIcon {
  animation: spin 1s infinite linear;
}

.checkboxSpace {
  margin-left: 0px !important;
}

Label {
  font-weight: normal !important;
  margin-left: 0.4rem !important;
}

.labelOverride {
  font-weight: bold;
  margin-left: -0.4rem;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
